import { subjectType } from "@/utils/config";
// import {addData} from "@/api/api";
import { saveSubject } from "@/api/api";
import { USER_TYPE_SCHOOL_ADMIN } from "@/utils/config";
export default {
  name: "index",
  props: ['Row'],
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {
        id: '',
        name: '',
        type: 1
      },
      subjectType: subjectType,
      gradeList: []
    };
  },
  mounted() {},
  methods: {
    closeDiv() {
      this.$emit('closeDiv');
    },
    getInfo(row) {
      //清空表单
      if (row) {
        this.Info = JSON.parse(row);
      } else {
        this.Info = {};
      }
    },
    subData() {
      //提交
      if (!this.Info.type || !this.Info.name) {
        this.$root.ElMessage.error('科目名称和类型不能为空！');
        return false;
      }
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user) return;
      if (user && user.type == USER_TYPE_SCHOOL_ADMIN) {
        this.Info.school_id = user.school_id;
      }
      saveSubject(this.Info).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.closeDiv();
          this.$emit('getList');
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    handleAvatarSuccess(e) {
      //图片上传成功后
      this.Info.businessLicenseImg = e.data;
    }
  }
};